<template>
  <div>
    <component
      :is="component"
      :name="component"
      ref="form"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import clone from '@/utils/clone';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingPersonal',
  components: {
    GeneralInformation: lazyLoad('views/Onboarding/Personal/GeneralInformation'),
    PersonalData: lazyLoad('views/Onboarding/Personal/PersonalData'),
    ContactData: lazyLoad('views/Onboarding/Personal/ContactData'),
    IdentificationData: lazyLoad('views/Onboarding/Personal/IdentificationData'),
    TaxInformation: lazyLoad('views/Onboarding/Personal/TaxInformation'),
    BankAccountInformation: lazyLoad('views/Onboarding/Personal/BankAccountInformation'),
    SourceOfWealthInformation: lazyLoad('views/Onboarding/Personal/SourceOfWealthInformation'),
    Overview: lazyLoad('views/Onboarding/Personal/Overview'),
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
    };
  },
  computed: {
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    component() {
      return this.$route.params.step;
    },
  },
  watch: {
    subStage(step) {
      if (step !== 'Intro') {
        this.$router.push({
          name: 'Onboarding.Personal',
          params: {
            step,
          },
        });
      }
    },
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setMainStage',
      'setSubStage',
      'updateOnboarding',
    ]),
    ...mapActions('onboarding', [
      'setLoading',
    ]),
    async submitForm() {
      await this.$refs.form.submit();
      this.setLoading(false);
    },
    async next() {
      this.setLoading(true);
      if (this.component === 'Overview') {
        const input = {
          investor: {},
          mainStage: 'personal',
          subStage: 'overview',
        };
        try {
          const nextStage = await this.updateOnboarding({
            input,
          });
          this.setMainStage(nextStage.nextMainStage);
          this.setSubStage(nextStage.nextSubStage);
        } catch (error) {
          this.$notify.error(this.$t('onboarding.personalData.error'));
        }
        this.setLoading(false);
        return this.$router.push({
          name: 'Onboarding.Overview',
        });
      }
      return this.submitForm();
    },
  },
};
</script>
